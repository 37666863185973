import React from 'react'
import styled from 'styled-components'

export default props =>{
const sessionNo = props.sessionno
const displaysession = props.displaysession
const sessionClass = props.sessionClass
var fDisplay = false

for(var i=0;i<displaysession.length;i++){
 if(displaysession[i] === sessionNo){
    fDisplay = true
 }
}

return(
<div className={sessionClass}>
  {fDisplay === true && <span>{props.children}</span>}
</div>
 )
}
