import React from 'react'
import { graphql } from 'gatsby'
import { Row, Grid } from '../components/responsive/'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Metadata from '../components/metadata'
import Session from '../components/sessionPlan'
import SessionTable from '../components/sessionTable'
import './index.css'
import './session.css'
import Image from '../components/image'
import Kunio from '../image/person/KunioNishimoto.png'
import Dris from '../image/person/DriesBuytaert.png'
import Takeshi from '../image/person/TakeshiKato.png'
import Person1 from '../image/person/1.png'
import Person2 from '../image/person/2.png'
import Person3 from '../image/person/3.png'
import Person4 from '../image/person/4.png'
import Person5 from '../image/person/5.png'
import Person6 from '../image/person/6.png'
import Person7 from '../image/person/7.png'
import Person8 from '../image/person/8.png'
import Person9 from '../image/person/9.png'
import Person10 from '../image/person/10.png'
import Person11 from '../image/person/11.png'
import Person12 from '../image/person/12.png'
import Person13 from '../image/person/13.png'
import Person14 from '../image/person/14.png'
import Person15 from '../image/person/15.png'

export default ({ data }) => (
  <div>
    <Metadata title="セッション" />
    <main className="contents page-sessions">
      <Header active="session" />
      <section>
        <div className="title">
          <h1>
            <img src={require('../image/session/Session.svg')} />
          </h1>
          <p>セッション</p>
        </div>
        <div className="schedule">
          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  13:00
                  <br className="sp" />
                  -13:15
                </p>
              </div>
            </div>
            <div className="schedule_content vertical">
              <div className="schedule_content_inbox">
                <div className="schedule_content_wrap">
                  <div className="schedule_content_block">
                    <h3>開会のご挨拶</h3>
                    <div className="schedule_content_namebox">
                      <div className="pic">
                        <img src={Kunio} />
                      </div>
                      <div className="text">
                        <p className="name">
                          <span className="big">
                            実行委員長　アーツ株式会社
                          </span>
                          &nbsp;<span className="small">代表取締役</span>
                          <br />
                          西本&nbsp;邦男<span className="small">氏</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="schedule_content_inbox">
                <div className="schedule_content_wrap">
                  <div className="schedule_content_block">
                    <h3>Dries Buytaertからのビデオメッセージ</h3>
                    <div className="schedule_content_namebox">
                      <div className="pic">
                        <img src={Dris} />
                      </div>
                      <div className="text">
                        <p className="name">
                          <span className="big">Acquia&nbsp;創業者・CTO</span>
                          <br />
                          Dries&nbsp;Buytaert<span className="small">氏</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="schedule_box border-bottom-none">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  13:20
                  <br className="sp" />
                  -13:55
                </p>
                <div className="special">Special Session</div>
              </div>
            </div>
            <div className="schedule_content vertical">
              <div className="schedule_content_inbox">
                <div className="schedule_content_wrap">
                  <div className="schedule_content_block">
                    <h3>官×民 副業で切り拓くトライセクターの新しい働き方</h3>

                    <div className="intext">
                      <p>ワクチン接種や10万円の特別定額給付金など、コロナ禍における行政の広報力不足を実感したという方も多いのではないでしょうか。<br />
一方、この1年の間で急速に進んだ働き方改革の象徴とも言えるのが、広報・マーケティング分野をはじめ、民間との「兼業プレイヤー」が行政の現場にも数多く誕生したことでしょう。<br />
働き方が多様化する中で、民間メインで働きつつ、非常勤公務員として官×民 複業を実践した経験を踏まえ、「官×民 複業で切り拓くトライセクターの新しい働き方」についてお話しさせていただきます。</p>

                      <ul className="tags">
                      	<li><a>全レベル</a></li>
                      	<li><a>全対象者</a></li>
                      	<li><a>事例</a></li>
                      </ul>
                    </div>

                    <div className="schedule_content_namebox gray">
                      <div className="pic"><img src={ Takeshi } /></div>
                      <div className="text">
                        <p className="name">
                          <span className="big">元文部科学省　大臣官房　広報戦略アドバイザー<br />一般社団法人　日本GR協会　事務局長</span>
                          <br />
                          加藤　健（たけし）<span className="small">氏</span>
                        </p>
                        <p className="des">新卒で転職支援の上場企業でWebマーケティングを経験後、ITベンチャーを経て、デジタルマーケティングのコンサルティング会社へ。2021年には東京都港区役所・区長室の広報専門職へと転身。非常勤国家公務員として文部科学省の大臣官房で広報戦略アドバイザーも務め、国×地方の公務員複業を実践。</p>
                        <ul>
                        	<li><a href="https://twitter.com/takeshi_kato" target="_blank">Twitter</a></li>
                        	<li><a href="https://www.facebook.com/takeshi.kato1204" target="_blank">Facebook</a></li>
                        	<li><a href="http://atcafe-media.com/takeshi_kato/" target="_blank">At Cafe</a></li>
                        </ul>
                      </div>
                    </div>



                    <div className="schedule_content_namebox gray">
                      <div className="pic"><img src={ Person13 } /></div>
                      <div className="text">
                        <p className="name">
                          <p className="small">聞き手：</p>
                          <span className="big">ANNNAI株式会社 </span>
                          <br />
                          太田垣 恭子<span className="small">氏</span>
                        </p>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time"></div>
            <div className="schedule_content horizontal plustrack">
              <div className="schedule_content_inbox">
                <div className="schedule_content_wrap"></div>
                <div className="schedule_content_track pc">
                  <img src={require('../image/session/tracka.svg')} />
                </div>
              </div>
              <div className="schedule_content_inbox pink">
                <div className="schedule_content_wrap"></div>
                <div className="schedule_content_track pc">
                  <img src={require('../image/session/trackb.svg')} />
                </div>
              </div>
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  14:00
                  <br className="sp" />
                  -14:20
                </p>
              </div>
            </div>
            <div className="schedule_content horizontal">
              {data.camp2021.rest.sessions.map((session, key) => {
                return (
                  <SessionTable
                    sessionno={session.field_filed_session_number}
                    displaysession={['1', '2']}
                    sessionClass={
                      session.field_filed_session_number &&
                      session.field_filed_session_number % 2 === 0
                        ? 'schedule_content_inbox pink'
                        : 'schedule_content_inbox'
                    }
                  >
                    <div className="schedule_content_track sp">
                      <img src={require('../image/session/tracka.svg')} />
                    </div>
                    <div key={key} className="schedule_content_block">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: session.field_session_html4,
                        }}
                      />

                      <div className="schedule_content_namebox gray">
                        <div className="pic">
                          <img
                            src={switchPerson(
                              session.field_filed_session_number
                            )}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html5,
                          }}
                        />
                      </div>

                      <div className={session.field_session_html6 && session.field_filed_session_number === '2' ? "schedule_content_namebox gray" : "displaynone"}>
                        <div className="pic">
                          <img
                            src={Person15}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html6,
                          }}
                        />
                        </div>

                    </div>
                  </SessionTable>
                )
              })}
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  14:30
                  <br className="sp" />
                  -14:50
                </p>
              </div>
            </div>
            <div className="schedule_content horizontal">
              {data.camp2021.rest.sessions.map((session, key) => {
                return (
                  <SessionTable
                    sessionno={session.field_filed_session_number}
                    displaysession={['3', '4']}
                    sessionClass={
                      session.field_filed_session_number &&
                      session.field_filed_session_number % 2 === 0
                        ? 'schedule_content_inbox pink'
                        : 'schedule_content_inbox'
                    }
                  >
                    <div className="schedule_content_track sp">
                      <img src={require('../image/session/tracka.svg')} />
                    </div>
                    <div key={key} className="schedule_content_block">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: session.field_session_html4,
                        }}
                      />

                      <div className="schedule_content_namebox gray">
                        <div className="pic">
                          <img
                            src={switchPerson(
                              session.field_filed_session_number
                            )}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html5,
                          }}
                        />
                      </div>
                    </div>
                  </SessionTable>
                )
              })}
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  15:00
                  <br className="sp" />
                  -15:20
                </p>
              </div>
            </div>
            <div className="schedule_content horizontal">
              {data.camp2021.rest.sessions.map((session, key) => {
                return (
                  <SessionTable
                    sessionno={session.field_filed_session_number}
                    displaysession={['5', '6']}
                    sessionClass={
                      session.field_filed_session_number &&
                      session.field_filed_session_number % 2 === 0
                        ? 'schedule_content_inbox pink'
                        : 'schedule_content_inbox'
                    }
                  >
                    <div className="schedule_content_track sp">
                      <img src={require('../image/session/tracka.svg')} />
                    </div>
                    <div key={key} className="schedule_content_block">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: session.field_session_html4,
                        }}
                      />

                      <div className="schedule_content_namebox gray">
                        <div className="pic">
                          <img
                            src={switchPerson(
                              session.field_filed_session_number
                            )}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html5,
                          }}
                        />
                      </div>
                    </div>
                  </SessionTable>
                )
              })}
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  15:30
                  <br className="sp" />
                  -15:50
                </p>
              </div>
            </div>
            <div className="schedule_content horizontal">
              {data.camp2021.rest.sessions.map((session, key) => {
                return (
                  <SessionTable
                    sessionno={session.field_filed_session_number}
                    displaysession={['7', '8']}
                    sessionClass={
                      session.field_filed_session_number &&
                      session.field_filed_session_number % 2 === 0
                        ? 'schedule_content_inbox pink'
                        : 'schedule_content_inbox'
                    }
                  >
                    <div className="schedule_content_track sp">
                      <img src={require('../image/session/tracka.svg')} />
                    </div>
                    <div key={key} className="schedule_content_block">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: session.field_session_html4,
                        }}
                      />

                      <div className="schedule_content_namebox gray">
                        <div className="pic">
                          <img
                            src={switchPerson(
                              session.field_filed_session_number
                            )}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html5,
                          }}
                        />
                      </div>
                    </div>
                  </SessionTable>
                )
              })}
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  16:00
                  <br className="sp" />
                  -16:20
                </p>
              </div>
            </div>
            <div className="schedule_content horizontal">
              {data.camp2021.rest.sessions.map((session, key) => {
                return (
                  <SessionTable
                    sessionno={session.field_filed_session_number}
                    displaysession={['9', '10']}
                    sessionClass={
                      session.field_filed_session_number &&
                      session.field_filed_session_number % 2 === 0
                        ? 'schedule_content_inbox pink'
                        : 'schedule_content_inbox'
                    }
                  >
                    <div className="schedule_content_track sp">
                      <img src={require('../image/session/tracka.svg')} />
                    </div>
                    <div key={key} className="schedule_content_block">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: session.field_session_html4,
                        }}
                      />

                      <div className="schedule_content_namebox gray">
                        <div className="pic">
                          <img
                            src={switchPerson(
                              session.field_filed_session_number
                            )}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html5,
                          }}
                        />
                      </div>
                    </div>
                  </SessionTable>
                )
              })}
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  16:30
                  <br className="sp" />
                  -16:50
                </p>
              </div>
            </div>
            <div className="schedule_content horizontal">
              {data.camp2021.rest.sessions.map((session, key) => {
                return (
                  <SessionTable
                    sessionno={session.field_filed_session_number}
                    displaysession={['11', '12']}
                    sessionClass={
                      session.field_filed_session_number &&
                      session.field_filed_session_number % 2 === 0
                        ? 'schedule_content_inbox pink'
                        : 'schedule_content_inbox'
                    }
                  >
                    <div className="schedule_content_track sp">
                      <img src={require('../image/session/tracka.svg')} />
                    </div>
                    <div key={key} className="schedule_content_block">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: session.field_session_html4,
                        }}
                      />

                      <div className="schedule_content_namebox gray">
                        <div className="pic">
                          <img
                            src={switchPerson(
                              session.field_filed_session_number
                            )}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html5,
                          }}
                        />
                      </div>
                    </div>
                  </SessionTable>
                )
              })}
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  17:00
                  <br className="sp" />
                  -17:20
                </p>
              </div>
            </div>
            <div className="schedule_content horizontal">
              {data.camp2021.rest.sessions.map((session, key) => {
                return (
                  <SessionTable
                    sessionno={session.field_filed_session_number}
                    displaysession={['13', '14']}
                    sessionClass={
                      session.field_filed_session_number &&
                      session.field_filed_session_number % 2 === 0
                        ? 'schedule_content_inbox pink'
                        : 'schedule_content_inbox'
                    }
                  >
                    <div className="schedule_content_track sp">
                      <img src={require('../image/session/tracka.svg')} />
                    </div>
                    <div key={key} className="schedule_content_block">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: session.field_session_html4,
                        }}
                      />

                      <div className="schedule_content_namebox gray">
                        <div className="pic">
                          <img
                            src={switchPerson(
                              session.field_filed_session_number
                            )}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: session.field_session_html5,
                          }}
                        />
                      </div>
                    </div>
                  </SessionTable>
                )
              })}
            </div>
          </div>

          <div className="schedule_box">
            <div className="schedule_time">
              <div className="schedule_time_text">
                <p>
                  17:30
                  <br className="sp" />
                  -18:00
                </p>
              </div>
            </div>
            <div className="schedule_content vertical">
              <div className="schedule_content_inbox">
                <div className="schedule_content_wrap">
                  <div className="schedule_content_block">
                    <h3>クロージングセッション・交流会</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </div>
)

const switchPerson = mode => {
  switch (mode) {
    case '1':
      return Person1
    case '2':
      return Person2
    case '3':
      return Person3
    case '4':
      return Person4
    case '5':
      return Person5
    case '6':
      return Person6
    case '7':
      return Person7
    case '8':
      return Person8
    case '9':
      return Person9
    case '10':
      return Person10
    case '11':
      return Person11
    case '12':
      return Person12
    case '13':
      return Person13
    case '14':
      return Person14
    case '15':
      return Person15
  }
}

export const query = graphql`
  query {
    camp2021 {
      rest {
        sessions {
          field_session_user
          field_session_name
          field_session_user_image
          field_session_end
          field_session_start
          field_session_track
          field_session_movie_url
          field_session_presentation_url
          field_session_html4
          field_session_html5
          field_session_html6
          field_filed_session_number
        }
      }
    }
    about: file(relativePath: { eq: "person/KunioNishimoto.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
